<!--
#########################################################################################
######    Vista SVC31200  :  BALANCEO DE CARGAS                                   ######
#########################################################################################
-->
<template>
    <div style="height:100%">
        <!-- Top Navigation (Genérico) -->
        <TNC10000></TNC10000>
  
        <!-- Rótulo -->
        <!-- <div class="igs-back-color2 igs-color0 igs-center">Preparación de Palés para los Cargadores</div> -->

        <!-- Rangos -->
        <div class="row igs-back-color8">
            <div class="col-md-3 igs-l4 igs-left igs-l-pad-20">

              <div class="row">
                <div class="col-md-5">
                  Refrescar cada ...
                  <select v-model="lnSegundosTimer" @change="lp90CambioTimer()">
                    <option value=0>No AutoRefrescar</option>
                    <option value=10>Cada 10 segundos</option>
                    <option value=20>Cada 20 segundos</option>
                    <option value=30>Cada 30 segundos</option>
                    <option value=60>Cada 60 segundos</option>
                    <option value=90>Cada 90 segundos</option>
                    <option value=120>Cada 120 segundos</option>
                    <option value=150>Cada 150 segundos</option>
                    <option value=180>Cada 180 segundos</option>
                  </select>            
                </div>
                <div class="col-md-5 igs-center">
                  Refrescar ahora ...<br/>
                  <span class="fa fa-refresh" aria-hidden="true" @click="lp98LeerFechasRutasCargasDisponibles()"></span>
                </div>
                <div class="col-md-2">
                  &nbsp;
                </div>
              </div>

            </div>
            <div class="col-md-1 igs-l4">
              Monitorizar ...
              <select v-model="lnMonitorizacion">
                <option value=0>Ocupación</option>
                <option value=1>Preparación</option>
              </select>
            </div>
            <div class="col-md-1 igs-l2">
              Tramo ({{this.lnPalesXTramo}} Pal.)
              <select v-model="lnTramoPales" @change="lp60RenovarListaPales()">
                <option value=0>1</option>
                <option value=1>2</option>
                <option value=2>3</option>
                <option value=3>4</option>
              </select>
              <br/>
              <span class="igs-mt-5">Palés x Pantalla&nbsp;</span>
              <select v-model="lnPalesXPantalla" @change="lp60RenovarListaPales()" class="igs-mt-5">
                <option value=2>2</option>
                <option value=4>4</option>
                <option value=6>6</option>
                <option value=8>8</option>
              </select>
            </div>
            <div class="col-md-3 igs-l2">
              <div class="row">
                <div class="col-md-4 igs-l3">Fecha<br/><b>{{ltFechaCarga}}</b></div>
                <div class="col-md-8 igs-l3">
                &nbsp;&nbsp;&nbsp;Ruta&nbsp;&nbsp;&nbsp;<span class="fa fa-refresh" aria-hidden="true" @click="lp60CambioFechaRutaCarga()"></span><br/><span v-html="ltRutaCarga"/>
                </div>
              </div>
            </div>
            <div class="col-md-2 igs-l2">Camión<br/><span v-html="ltCamionCarga"/></div>
            <div class="col-md-2 igs-l2 igs-right igs-r-pad-20">Cargador&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Camino)<br/>
              <select v-model="ltAlmaceneroGlobal" @change="lp60CambioAlmaceneroGlobal()" :disabled="lbCargaBloqueada">
                <option key="k_laLAG_Vacia" value="">-- Almacenero Libre --</option>
                <option v-for="item in laListaAlmaceneros" :key="item.ocl0" :value="item.ocl0"><span v-html="lf95DescripcionAlmacenero(item)"></span></option>
              </select>
              &nbsp;&nbsp;
              <span v-html="ltTipoCYR"/>
              &nbsp;&nbsp;
            </div>
        </div>

        <div style="height:80vh">

          <div class="row">
            <!--------------------------->
            <!-- Artículos NO CARGADOS -->
            <!--------------------------->
            <div class="col-3 igs-l-pad-20 igs-mt-5">
                <div style="width:98%;">
                  <div>
                    <div class="igs-back-color2 igs-color0">
                      <div class="row">
                        <div class="col-md-4 igs-l3 igs-mt-5 igs-center"><input class="igs-radio-back-color7" type="radio" value="1" v-model="ltAmbitoBusqueda" @change="lp98LeerFechasRutasCargasDisponibles()"/>&nbsp;En Pedidos</div>
                        <div class="col-md-4 igs-l3 igs-mt-5 igs-center"><input class="igs-radio-back-color7" type="radio" value="2" v-model="ltAmbitoBusqueda" @change="lp98LeerFechasRutasCargasDisponibles()"/>&nbsp;En Reparto</div>
                        <div class="col-md-4 igs-l3 igs-mt-5 igs-center"><input class="igs-radio-back-color7" type="radio" value="3" v-model="ltAmbitoBusqueda" @change="lp98LeerFechasRutasCargasDisponibles()"/>&nbsp;Entregado</div>                        
                      </div>
                      <div class="row" v-if="ltAmbitoBusqueda != '1'">
                        <div class="col-md-3 igs-center igs-l3 igs-mt-5">Fechas</div>
                        <div class="col-md-9 igs-center">
                          <date-range-picker
                              ref="dp_RangoFechasDetalleMovimientos"
                              opens="right"
                              :append-to-body=true
                              :single-date-picker=false
                              :locale-data="dp1_localeData"
                              minDate="2023-01-01" maxDate="2035-12-31"
                              :timePicker=false
                              :timePicker24Hour=true
                              :showWeekNumbers=true
                              :showDropdowns=true
                              :autoApply=true
                              :ranges="dp1_defaultRanges"
                              :linkedCalendars=false
                              control-container-class="igs-btn-dh-fecha"
                              v-model="dp1_dateRange"
                              @update="lp30RangoFechasDetalleMovimientos"
                              >
                            <template v-slot:input="picker">
                              <div class="igs-color0 igs-l3 igs-mt-5">
                                <b>{{ picker.startDate | lf60FiltroFecha }} a {{ picker.endDate | lf60FiltroFecha }}</b>
                              </div>
                            </template>
                          </date-range-picker>
                        </div>
                      </div>
                      <div class="row igs-v-center" style="height:5vh;">
                          <div class="col-md-12 igs-vl4 igs-bold">Fechas y Rutas Disponibles ...</div>
                      </div>
                    </div>
                    <select v-model="ltFechaRutaCargaSeleccionada" @change="lp60CambioFechaRutaCarga()" class="igs-fs-14 " size="24" style="border:0px;outline:0px;">
                        <!-- <option key="k_laFRCS_Vacia" value="">-- Selecciona una Fecha y Ruta --</option> -->
                        <option v-for="item in laFechasRutasCargasDisponibles" :key="item.ccl0" :value="item.ccl0"><span v-html="lf95EstadoCarga(item.c991)"></span> {{ f70AMD2fDMA(item.c001) }} {{ item.c002 }} - {{ item.r002 }} ({{ item.c009 }})</option>
                    </select>              
                  </div>

                  <div class="igs-back-color7 igs-color0">
                    <div class="row igs-v-center">
                      <div class="col-12 igs-center"><b>Artículos Pendientes</b></div>
                    </div>
                  </div>

                  <div class="igs-color1 igs-l3">

                    <input type="checkbox"  v-model="lbCKArticulosNoCargados" @click="lp55SeleccionarTodosPendientes()" class="igs-mt-2" />&nbsp;

                    <span class="fa-solid fa-align-center"></span>&nbsp;
                    <span class="igs-bold">{{lf71AcumuladoReferenciasPale(0)}}</span>&nbsp;

                    <span :class="lf91ClasePorcentajePale(0)">({{f91Porcentaje(lf71PorcentajeOcupacionPale(0))}}%)</span>&nbsp;&nbsp;

                    <span class="fa-solid fa-th"></span>&nbsp;
                    <span class="igs-bold">{{f91Entero(lf71AcumuladoCoeficientesPale(0))}}</span>&nbsp;

                    <span class="fa-solid fa-balance-scale"></span>&nbsp;
                    <span class="igs-bold">{{f91Importe(lf71AcumuladoPesoPale(0))}}</span>&nbsp;&nbsp;

                    <span class="fa-solid fa-stop"></span>&nbsp;
                    <span class="igs-bold">{{lf71AcumuladoUnidadesPale(0)}}</span>&nbsp;
                    
                    <span class="fa-solid fa-th-large"></span>&nbsp;
                    <span class="igs-bold">{{lf71AcumuladoCajasPale(0)}}</span>&nbsp;

                  </div>
                  <draggable v-model="laRegistrosPale[0]" group="CXP" @start="drag=true" @end="drag=false" :move="lf88PermitidoMover" style="width:100%" class="igs-ml-12">
                    <div v-for="(p, index) in laRegistrosPale[0]" :key="p.ycl0" class="row" style="border-style:dotted; border-width:1px; border-color:#0f0f0f; width:100%; text-align:left;padding:0 0 0 0;" >
                      <div class="col-md-1 igs-center">
                        <input type="checkbox" v-model="laCKArticulosNoCargados" :value="p.ycl0" />
                      </div>
                      <div class="col-md-9 igs-vl3 igs-l-pad-2">
                        <span class="igs-fs-8">({{index+1}})</span> <code>{{p.y006}}</code> <b :class="lf91ClaseDescripcionArticulo(p.a002)">{{ p.a002 }}</b></div>
                      <div class="col-md-2 igs-l4 igs-color7 igs-right igs-bold">{{lf91CantidadACargar(p.a040,p.y060,p.y061)}}</div>
                    </div>
                  </draggable>

                </div>
            </div>

            <div class="col-9 igs-pad-2">

              <!-- Pills (Barra Navegación) con Rango de Palés de CADA Pantalla -->
              <div class="row">
                <div class="col-md-10">
                  <nav class="nav nav-pills flex-column flex-sm-row igs-pad-4">
                    <a v-for="indiceTabPill in lnPalesNroTabs" :class="lf91ClaseNavPales(indiceTabPill-1)" href="#" @click="lp60VistaPales(indiceTabPill-1);" :key="indiceTabPill-1"><b>{{ lf91RotuloTabPales(indiceTabPill-1) }}</b></a>
                  </nav>
                </div>
                <div class="col-md-2 igs-mt-10 igs-center igs-mr-2">
                  <span class="fa-solid fa-calculator igs-l-pad-20" v-if="!lbCargaBloqueada" @click="lp60PropuestaCarga()"></span>&nbsp;&nbsp;
                  <span class="fa-solid fa-lock igs-l-pad-20" v-if="lbCargaBloqueada" @click="lp60ConmutarBloqueo()"></span>&nbsp;&nbsp;
                  <span class="fa-solid fa-lock-open igs-l-pad-20" v-if="!lbCargaBloqueada" @click="lp60ConmutarBloqueo()"></span>&nbsp;&nbsp;
                  <span class="fa-solid fa-floppy-disk igs-l-pad-20" v-if="!lbCargaBloqueada" @click="lp98GrabarDetalleCarga()"></span>&nbsp;&nbsp;
                </div>
              </div>

              <div v-for="indiceTabPale in lnPalesNroTabs" :key="indiceTabPale">
                  <!--
                  IndiceActivo : {{lnVistaPalesIndiceActivo}}
                  <br/>
                  {{ indiceTabPale }} ({{  lf30PaleMinimo(indiceTabPale-1) }} - {{  lf30PaleMaximo(indiceTabPale-1) }})
                  <br/>
                  <span v-for="i1 in (lf30PaleMaximo(indiceTabPale-1) - lf30PaleMinimo(indiceTabPale-1) + 1) / 2" :key="i1"> {{ i1+lf30PaleMinimo(indiceTabPale-1)-1 }}</span>
                  <br/>
                  <span v-for="i1 in (lf30PaleMaximo(indiceTabPale-1) - lf30PaleMinimo(indiceTabPale-1) + 1) / 2" :key="i1"> {{ i1+lf30PaleMinimo(indiceTabPale-1)+(lnPalesXPantalla/2)-1 }}</span>
                  <hr/>
                  -->


                  <div v-if="lnVistaPalesIndiceActivo == (indiceTabPale-1)">
                    <!-------------------->
                    <!-- Parte SUPERIOR -->
                    <!-------------------->
                    <div class="row" style="height:36vh; width:101%;">
                      <div v-for="i1 in (lf30PaleMaximo(indiceTabPale-1) - lf30PaleMinimo(indiceTabPale-1) + 1) / 2"  :key="'PS_'+i1" style="height:35vh;" :class="lf91ClaseColumnaPale(indiceTabPale)">
                        <div :class="lf91ClaseBannerPale(i1+lf30PaleMinimo(indiceTabPale-1)-1)">
                          <div class="row">
                            <div class="col-5 igs-left"><span class="fa-solid fa-file-import igs-l-pad-20" @click="lp59MoverPendientesAPale(i1+lf30PaleMinimo(indiceTabPale-1)-1)"></span></div>
                            <div class="col-7 igs-left">Palé {{i1+lf30PaleMinimo(indiceTabPale-1)-1}}</div>
                          </div>
                        </div>
                        <div :class="lf91ClaseResumenPale()" v-if="laRegistrosPale[i1+lf30PaleMinimo(indiceTabPale-1)-1] && laRegistrosPale[i1+lf30PaleMinimo(indiceTabPale-1)-1].length > 0">

                          <span class="fa-solid fa-align-center"></span>&nbsp;
                          <span class="igs-bold">{{lf71AcumuladoReferenciasPale(i1+lf30PaleMinimo(indiceTabPale-1)-1)}}</span>&nbsp;

                          <span :class="lf91ClasePorcentajePale(i1+lf30PaleMinimo(indiceTabPale-1)-1)">({{f91Porcentaje(lf71PorcentajeOcupacionPale(i1+lf30PaleMinimo(indiceTabPale-1)-1))}}%)</span>&nbsp;&nbsp;

                          <span class="fa-solid fa-th"></span>&nbsp;
                          <span class="igs-bold">{{f91Entero(lf71AcumuladoCoeficientesPale(i1+lf30PaleMinimo(indiceTabPale-1)-1))}}</span>&nbsp;

                          <span class="fa-solid fa-balance-scale"></span>&nbsp;
                          <span class="igs-bold">{{f91Importe(lf71AcumuladoPesoPale(i1+lf30PaleMinimo(indiceTabPale-1)-1))}}</span>&nbsp;&nbsp;

                          <span class="fa-solid fa-stop"></span>&nbsp;
                          <span class="igs-bold">{{lf71AcumuladoUnidadesPale(i1+lf30PaleMinimo(indiceTabPale-1)-1)}}</span>&nbsp;
                          
                          <span class="fa-solid fa-th-large"></span>&nbsp;
                          <span class="igs-bold">{{lf71AcumuladoCajasPale(i1+lf30PaleMinimo(indiceTabPale-1)-1)}}</span>&nbsp;

                        </div>
                        <draggable v-model="laRegistrosPale[i1+lf30PaleMinimo(indiceTabPale-1)-1]" group="CXP" @start="drag=true" @end="drag=false" :move="lf88PermitidoMover" style="width:100%" class="igs-ml-12">
                          <div v-for="(p, index) in laRegistrosPale[i1+lf30PaleMinimo(indiceTabPale-1)-1]" :key="p.ycl0" class="row" style="border-style:dotted; border-width:1px; border-color:#0f0f0f; width:100%; text-align:left;padding:0 0 0 0;" >
                            <div :class="lf91ClaseFilaPale()"><span class="igs-fs-9">({{index+1}})</span> <code>{{p.y006}}</code> <b :class="lf91ClaseMonitorizacionFila(index,p.y082)">{{ p.a002 }}</b> <br/><code>{{p.e007}} ({{ p.y088 }}) <!--{{p.y082}}-->{{lf91FechaHoraCarga(p.y093,p.y094)}}</code> <span  v-html="f93MaquetarUbicacion(p.y081,5)"></span></div>
                            <div class="col-md-2 igs-l4 igs-color7 igs-right igs-bold">{{lf91CantidadACargar(p.a040,p.y060,p.y061)}}</div>
                          </div>
                        </draggable>
                        <div :class="lf91ClasePiePale()" v-if="laRegistrosPale[i1+lf30PaleMinimo(indiceTabPale-1)-1] && laRegistrosPale[i1+lf30PaleMinimo(indiceTabPale-1)-1].length > 0">
                          <select v-model="laAlmacenerosPale[i1+lf30PaleMinimo(indiceTabPale-1)-1]" :disabled="lbCargaBloqueada">
                            <option key="'k_laLAG_Vacia_'+i1+lf30PaleMinimo(indiceTabPale-1)-1" value="">-- Almacenero Libre --</option>
                            <option v-for="item in laListaAlmaceneros" :key="item.ocl0" :value="item.ocl0"><span v-html="lf95DescripcionAlmacenero(item)"></span></option>
                          </select>
                          <span v-html="lf94TiempoPreparacionPale(i1+lf30PaleMinimo(indiceTabPale-1)-1)"/>
                        </div>
                      </div>
                    </div>

                    <!-------------------->
                    <!-- Parte INFERIOR -->
                    <!-------------------->
                    <div class="row" style="height:36vh; width:101%;">
                      <div v-for="i1 in (lf30PaleMaximo(indiceTabPale-1) - lf30PaleMinimo(indiceTabPale-1) + 1) / 2"  :key="'PS_'+i1" :class="lf91ClaseColumnaPale(indiceTabPale)">
                        <div :class="lf91ClaseBannerPale(i1+lf30PaleMinimo(indiceTabPale-1)+(lnPalesXPantalla/2)-1)">
                          <div class="row">
                            <div class="col-5 igs-left"><span class="fa-solid fa-file-import igs-l-pad-20"  @click="lp59MoverPendientesAPale(i1+lf30PaleMinimo(indiceTabPale-1)+(lnPalesXPantalla/2)-1)"></span></div>
                            <div class="col-7 igs-left">Palé {{i1+lf30PaleMinimo(indiceTabPale-1)+(lnPalesXPantalla/2)-1}}</div>
                          </div>
                        </div>
                        <div :class="lf91ClaseResumenPale()" v-if="laRegistrosPale[i1+lf30PaleMinimo(indiceTabPale-1)+(lnPalesXPantalla/2)-1] && laRegistrosPale[i1+lf30PaleMinimo(indiceTabPale-1)+(lnPalesXPantalla/2)-1].length > 0">

                          <span class="fa-solid fa-align-center"></span>&nbsp;
                          <span class="igs-bold">{{lf71AcumuladoReferenciasPale(i1+lf30PaleMinimo(indiceTabPale-1)+(lnPalesXPantalla/2)-1)}}</span>&nbsp;

                          <span :class="lf91ClasePorcentajePale(i1+lf30PaleMinimo(indiceTabPale-1)+(lnPalesXPantalla/2)-1)">({{f91Porcentaje(lf71PorcentajeOcupacionPale(i1+lf30PaleMinimo(indiceTabPale-1)+(lnPalesXPantalla/2)-1))}}%)</span>&nbsp;&nbsp;

                          <span class="fa-solid fa-th"></span>&nbsp;
                          <span class="igs-bold">{{f91Entero(lf71AcumuladoCoeficientesPale(i1+lf30PaleMinimo(indiceTabPale-1)+(lnPalesXPantalla/2)-1))}}</span>&nbsp;

                          <span class="fa-solid fa-balance-scale"></span>&nbsp;
                          <span class="igs-bold">{{f91Importe(lf71AcumuladoPesoPale(i1+lf30PaleMinimo(indiceTabPale-1)+(lnPalesXPantalla/2)-1))}}</span>&nbsp;&nbsp;

                          <span class="fa-solid fa-stop"></span>&nbsp;
                          <span class="igs-bold">{{lf71AcumuladoUnidadesPale(i1+lf30PaleMinimo(indiceTabPale-1)+(lnPalesXPantalla/2)-1)}}</span>&nbsp;
                          
                          <span class="fa-solid fa-th-large"></span>&nbsp;
                          <span class="igs-bold">{{lf71AcumuladoCajasPale(i1+lf30PaleMinimo(indiceTabPale-1)+(lnPalesXPantalla/2)-1)}}</span>&nbsp;

                        </div>
                        <draggable v-model="laRegistrosPale[i1+lf30PaleMinimo(indiceTabPale-1)+(lnPalesXPantalla/2)-1]" group="CXP" @start="drag=true" @end="drag=false" :move="lf88PermitidoMover" style="width:100%" class="igs-ml-12">
                          <div v-for="(p, index) in laRegistrosPale[i1+lf30PaleMinimo(indiceTabPale-1)+(lnPalesXPantalla/2)-1]" :key="p.ycl0" class="row" style="border-style:dotted; border-width:1px; border-color:#0f0f0f; width:100%; text-align:left;padding:0 0 0 0;" >
                            <div :class="lf91ClaseFilaPale()"><span class="igs-fs-9">({{index+1}})</span> <code>{{p.y006}}</code> <b :class="lf91ClaseMonitorizacionFila(index,p.y082)">{{ p.a002 }}</b> <br/><code>{{p.e007}} ({{ p.y088 }}) <!-- {{p.y082}} --> {{lf91FechaHoraCarga(p.y093,p.y094)}}</code> <span  v-html="f93MaquetarUbicacion(p.y081,5)"></span></div>
                            <div class="col-md-2 igs-l4 igs-color7 igs-right igs-bold">{{lf91CantidadACargar(p.a040,p.y060,p.y061)}}</div>
                          </div>
                        </draggable>
                        <div :class="lf91ClasePiePale()" v-if="laRegistrosPale[i1+lf30PaleMinimo(indiceTabPale-1)+(lnPalesXPantalla/2)-1] && laRegistrosPale[i1+lf30PaleMinimo(indiceTabPale-1)+(lnPalesXPantalla/2)-1].length > 0">
                          <select v-model="laAlmacenerosPale[i1+lf30PaleMinimo(indiceTabPale-1)+(lnPalesXPantalla/2)-1]" :disabled="lbCargaBloqueada">
                            <option key="'k_laLAG_Vacia_'+i1+lf30PaleMinimo(indiceTabPale-1)+(lnPalesXPantalla/2)-1" value="">-- Almacenero Libre --</option>
                            <option v-for="item in laListaAlmaceneros" :key="item.ocl0" :value="item.ocl0"><span v-html="lf95DescripcionAlmacenero(item)"></span></option>
                          </select>
                          <span v-html="lf94TiempoPreparacionPale(i1+lf30PaleMinimo(indiceTabPale-1)+(lnPalesXPantalla/2)-1)"/>
                        </div>
                      </div>
                    </div>

                  </div>

              </div>
            </div>
          </div>


          <!--
          <div v-if="lnVistaPalesIndiceActivo == 1">
            <span>Palés 7-12</span>
          </div>

          <div v-if="lnVistaPalesIndiceActivo == 2">
            <span>Palés 13-18</span>
          </div>

          <div v-if="lnVistaPalesIndiceActivo == 3">
            <span>Palés 19-24</span>
          </div>
          -->

        </div>

  
        <BNC10000></BNC10000>
    </div>
  </template>
  
  <script>
  import igs_mixin from '@/igs-mixins/igs_mixin'
  import TNC10000  from '@/igs-navegacion/TNC10000'
  import BNC10000  from '@/igs-navegacion/BNC10000'
  import axios     from 'axios';

  //-- Rango de Fechas
  import DateRangePicker from 'vue2-daterange-picker'
  import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

  export default {
    mixins:       [ igs_mixin ],
    components :  { TNC10000, BNC10000, DateRangePicker },
  
    //----------------------------
    //-- Datos Locales
    //----------------------------
    data() {
      //-- Valores Predeterminado
      let startDate = new Date();
      let endDate   = new Date();
      startDate.setDate(endDate.getDate() - 1);

      let today = new Date()
      today.setHours(0, 0, 0, 0)

      let yesterday = new Date()
      yesterday.setDate(today.getDate() - 1)
      yesterday.setHours(0, 0, 0, 0);

      var thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      var thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0, 11, 59, 59, 999);

      return {
         //------------------------------------------------------------------------
         //-- Variables Locales
         //------------------------------------------------------------------------
         ltPKCarga : '',                            //-- Primary Key Carga Editada
         ltFechaCarga : '',                         //-- Fecha de la Carga Editada
         ltRutaCarga : '',                          //-- Ruta de la Carga Editada
         ltCamionCarga : '',                        //-- Camión de la Carga Editada
         ltTipoCYR : '',                            //-- Tipo de Camino y Recorrido
         lnPalesCarga : 1,                          //-- Número de Palés del Camión Actual
         ltAmbitoBusqueda : '1',                    //-- Ambito de Busqueda (1-Pedidos, 2-Reparto, 3-Documentos Archivados)

         ltAlmaceneroGlobal : '',                   //-- Código del Almacenero GLOBAL (Todos los Palés)
         laListaAlmaceneros : [],                   //-- Lista de Almaceneros disponible

         lrRefreshTimer: null,                      //-- Referencia al Timer Vigente
         lnSegundosTimer : 0,                       //-- Frecuencia de AutoRefresco

         lnMonitorizacion : 0,                      //-- Tipo de Monitorización
                                                    //--  (0) - Llenado de Palé
                                                    //--  (1) - Preparación Carga
         lnTramoPales : 0,                          //-- Tramo (0-3) de (24 Palés)
         lnPalesXTramo : 24,                        //-- Número de Palés x Tramo

         lnPalesXPantalla : 6,                      //-- Número de Palés SIMULTÁNEOS (por pantalla)
         lnPalesNroMaximo : 96,                     //-- Número MÁXIMO de Palés (para la presente RUTA) (0)=Artículos Pendientes (1-nn) : Palé Individual

         lnPalesNroTabs : 4,                        //-- Número de (Tabs) pra Navegar entre los Palés
         lnVistaPalesIndiceActivo : 0,              //-- Indice del (Tab) Visible (0-N)
         ltFechaRutaCargaSeleccionada : '',         //-- Primary Key de la (Fecha + Ruta + Nro. Carga) Seleccionada
         lbCargaBloqueada : true,                   //-- Fecha+Ruta+Carga Bloqueada

         laFechasRutasCargasDisponibles : [],       //-- Lista de Fechas+Rutas+Nro.Carga Pendientes
         laCKArticulosNoCargados : [],              //-- Array con (CheckBox) de Artículos Pendientes Seleccionados
         lbCKArticulosNoCargados : false,           //-- (CheckBox) Grupal de Artículos Pendientes


         laAlmacenerosPale : [
          '','','','','','','','','','','','','','','','','','','','','','','','',''
         ],                                         //-- Array con Códigos de Almaceneros asignados a CADA Palé

         laFHMinimaPale : [],                       //-- Array con la Fecha y Hora MINIMAS de CADA Palé
         laFHMaximaPale : [],                       //-- Array con la Fecha y Hora MÁXIMAS de CADA Palé

         laRegistrosPale : [
         [
            { ycl0 : "001", y006: "112233", a002:'0Coca-Cola 200cc',            a017:'C', a040:'N', y060:20,  y061:0, y089:0, y092:'',    y912:15,  y921:18, y991:0.005,  ysw1:false },
            { ycl0 : "002", y006: "114433", a002:'0Queso Arzúa Ulloa',          a017:'B', a040:'S', y060:3.5, y061:2, y089:0, y092:'',    y912:3.5, y921:2,  y991:0.012,  ysw1:false },
            { ycl0 : "003", y006: "118833", a002:'0Tónica Schweppes 180cc',     a017:'C', a040:'N', y060:18,  y061:0, y089:0, y092:'',    y912:42,  y921:14, y991:0.21,   ysw1:false },
            { ycl0 : "004", y006: "112299", a002:'0Zumo Vida Melocotón, 200cc', a017:'C', a040:'N', y060:4,   y061:0, y089:0, y092:'',    y912:8.2, y921:3,  y991:0.13,   ysw1:false }
          ],
          [
            { ycl0 : "101", y006: "112233", a002:'1Coca-Cola 200cc',           a017:'C', a040:'N', y060:20,  y061:0,  y089:1, y092:'713', y912:15,  y921:18, y991:0.005,  ysw1:false },
            { ycl0 : "102", y006: "114433", a002:'1Fanta Naranja 1200',        a017:'B', a040:'S', y060:3.5, y061:2,  y089:1, y092:'',    y912:3.5, y921:2,  y991:0.012,  ysw1:false },
            { ycl0 : "103", y006: "118833", a002:'1Tónica Schweppes 180cc',    a017:'C', a040:'N', y060:18,  y061:0,  y089:1, y092:'',    y912:42,  y921:14, y991:0.21,   ysw1:false },
            { ycl0 : "104", y006: "112299", a002:'1Zumo Vida Melocotón, 200cc', a017:'C', a040:'N', y060:4,   y061:0, y089:1, y092:'',    y912:8.2, y921:3,  y991:0.13,   ysw1:false }
          ],
          [
            { ycl0 : "201", y006: "112233", a002:'2Coca-Cola 200cc',            a017:'C', a040:'N', y060:20,  y061:0, y089:2, y092:'703', y912:15,  y921:18, y991:0.005,  ysw1:false },
            { ycl0 : "202", y006: "114433", a002:'2Fanta Naranja 1200',         a017:'B', a040:'S', y060:3.5, y061:2, y089:2, y092:'',    y912:3.5, y921:2,  y991:0.012,  ysw1:false },
            { ycl0 : "203", y006: "118833", a002:'2Tónica Schweppes 180cc',     a017:'C', a040:'N', y060:18,  y061:0, y089:2, y092:'',    y912:42,  y921:14, y991:0.21,   ysw1:false },
            { ycl0 : "204", y006: "112299", a002:'2Zumo Vida Melocotón, 200cc',  a017:'C', a040:'N', y060:4,  y061:0, y089:2, y092:'',    y912:8.2, y921:3,  y991:0.13,   ysw1:false }
          ],
          [
            { ycl0 : "301", y006: "112233", a002:'3Coca-Cola 200cc',            a017:'C', a040:'N', y060:20,  y061:0, y089:3, y092:'',    y912:15,  y921:18, y991:0.005,  ysw1:false },
            { ycl0 : "302", y006: "114433", a002:'3Fanta Naranja 1200',         a017:'B', a040:'S', y060:3.5, y061:2, y089:3, y092:'',    y912:3.5, y921:2,  y991:0.012,  ysw1:false },
            { ycl0 : "303", y006: "118833", a002:'3Tónica Schweppes 180cc',     a017:'C', a040:'N', y060:18,  y061:0, y089:3, y092:'',    y912:42,  y921:14, y991:0.21,   ysw1:false  },
            { ycl0 : "304", y006: "112299", a002:'3Zumo Vida Melocotón, 200cc',  a017:'C', a040:'N', y060:4,  y061:0, y089:3, y092:'',    y912:8.2, y921:3,  y991:0.13,   ysw1:false }
          ],
          [
            { ycl0 : "401", y006: "112233", a002:'4Coca-Cola 200cc',            a017:'C', a040:'N', y060:20,  y061:0, y089:4, y092:'703', y912:15,  y921:18, y991:0.005,  ysw1:false },
            { ycl0 : "402", y006: "114433", a002:'4Fanta Naranja 1200',         a017:'B', a040:'S', y060:3.5, y061:2, y089:4, y092:'',    y912:3.5, y921:2,  y991:0.012,  ysw1:false },
            { ycl0 : "403", y006: "118833", a002:'4Tónica Schweppes 180cc',     a017:'C', a040:'N', y060:18,  y061:0, y089:4, y092:'',    y912:42,  y921:14, y991:0.21,   ysw1:false },
            { ycl0 : "404", y006: "112299", a002:'4Zumo Vida Melocotón, 200cc',  a017:'C', a040:'N', y060:4,  y061:0, y089:4, y092:'',    y912:8.2, y921:3,  y991:0.13,   ysw1:false }
          ],
          [
            { ycl0 : "501", y006: "112233", a002:'5Coca-Cola 200cc',            a017:'C', a040:'N', y060:20,  y061:0, y089:5, y092:'713', y912:15,  y921:18, y991:0.005,  ysw1:false },
            { ycl0 : "502", y006: "114433", a002:'5Fanta Naranja 1200',         a017:'B', a040:'S', y060:3.5, y061:2, y089:5, y092:'',    y912:3.5, y921:2,  y991:0.012,  ysw1:false },
            { ycl0 : "503", y006: "118833", a002:'5Tónica Schweppes 180cc',     a017:'C', a040:'N', y060:18,  y061:0, y089:5, y092:'',    y912:42,  y921:14, y991:0.21,   ysw1:false },
            { ycl0 : "504", y006: "112299", a002:'5Zumo Vida Melocotón, 200cc',  a017:'C', a040:'N', y060:4,  y061:0, y089:5, y092:'',    y912:8.2, y921:3,  y991:0.13,   ysw1:false }
          ],
          [
            { ycl0 : "601", y006: "112233", a002:'6Coca-Cola 200cc',            a017:'C', a040:'N', y060:20,  y061:0, y089:6, y092:'',    y912:15,  y921:18, y991:0.005,  ysw1:false },
            { ycl0 : "602", y006: "114433", a002:'6Fanta Naranja 1200',         a017:'B', a040:'S', y060:3.5, y061:2, y089:6, y092:'',    y912:3.5, y921:2,  y991:0.012,  ysw1:false },
            { ycl0 : "603", y006: "118833", a002:'6Tónica Schweppes 180cc',     a017:'C', a040:'N', y060:18,  y061:0, y089:6, y092:'',    y912:42,  y921:14, y991:0.21,   ysw1:false },
            { ycl0 : "604", y006: "112299", a002:'6Zumo Vida Melocotón, 200cc',  a017:'C', a040:'N', y060:4,  y061:0, y089:6, y092:'',    y912:8.2, y921:3,  y991:0.13,   ysw1:false }
          ],
          [
            { ycl0 : "701", y006: "112233", a002:'7Coca-Cola 200cc',            a017:'C', a040:'N', y060:20,  y061:0, y089:7, y092:'',    y912:15,  y921:18, y991:0.005,  ysw1:false },
            { ycl0 : "702", y006: "114433", a002:'7Fanta Naranja 1200',         a017:'B', a040:'S', y060:3.5, y061:2, y089:7, y092:'',    y912:3.5, y921:2,  y991:0.012,  ysw1:false },
            { ycl0 : "703", y006: "118833", a002:'7Tónica Schweppes 180cc',     a017:'C', a040:'N', y060:18,  y061:0, y089:7, y092:'',    y912:42,  y921:14, y991:0.21,   ysw1:false },
            { ycl0 : "704", y006: "112299", a002:'7Zumo Vida Melocotón, 200cc', a017:'C', a040:'N', y060:4,   y061:0, y089:7, y092:'',    y912:8.2, y921:3,  y991:0.13,   ysw1:false  }
          ],
          [
            { ycl0 : "801", y006: "112233", a002:'8Coca-Cola 200cc',            a017:'C', a040:'N', y060:20,  y061:0, y089:8, y092:'',    y912:15,  y921:18, y991:0.005,  ysw1:false },
            { ycl0 : "802", y006: "114433", a002:'8Fanta Naranja 1200',         a017:'B', a040:'S', y060:3.5, y061:2, y089:8, y092:'',    y912:3.5, y921:2,  y991:0.012,  ysw1:false },
            { ycl0 : "803", y006: "118833", a002:'8Tónica Schweppes 180cc',     a017:'C', a040:'N', y060:18,  y061:0, y089:8, y092:'',    y912:42,  y921:14, y991:0.21,   ysw1:false  },
            { ycl0 : "804", y006: "112299", a002:'8Zumo Vida Melocotón, 200cc',  a017:'C', a040:'N', y060:4,  y061:0, y089:8, y092:'',    y912:8.2, y921:3,  y991:0.13,   ysw1:false }
          ]
         ],                                            //-- Detalle de Artículos x Palé

         ltFiltroDesdeFecha   : '00000000',      
         ltFiltroHastaFecha   : '99999999',

         dp1_dateRange: {startDate, endDate},
         dp1_localeData : {
            direction: 'ltr',
            format: 'dd-mm-yyyy',
            separator: ' a ',
            applyLabel: 'Confirmar',
            cancelLabel: 'Cancelar',
            weekLabel: 'W',
            customRangeLabel: 'Rango',
            daysOfWeek: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
            monthNames: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            firstDay: 1
         },
         dp1_defaultRanges : {
            'Hoy': [today, today],
            'Ayer': [yesterday, yesterday],
            'Mes Actual': [thisMonthStart, thisMonthEnd],
            'Mes Anterior': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
            'Año Actual': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)]
         }
          
      }
                
  
    },
  
    //----------------------------
    //-- Métodos Locales
    //----------------------------
    methods: {


      //.................................................
      //-- Inicializar variables relacionadas con Palés
      //.................................................
      lp10InicializarPales() {
        //-- Inicializaciones
        let laNewAlmacenerosPale  = [''];     //.. Listas de Almaceneros x Palé
        let laNewRegistrosPale    = [[]];     //.. Listas de Artículos x Palé
        let laNewFHMinimaPale     = [''];     //.. Listas de Fechas y Horas Mínimas x Palé
        let laNewFHMaximaPale     = [''];     //.. Listas de Fechas y Horas Máximas x Palé

        let i = 0;
        while (i < this.lnPalesNroMaximo) {
          laNewAlmacenerosPale.push('');
          laNewRegistrosPale.push([]);
          i += 1;
        }
        
        this.laAlmacenerosPale  = laNewAlmacenerosPale;
        this.laRegistrosPale    = laNewRegistrosPale;

        this.laFHMinimaPale     = laNewFHMinimaPale;
        this.laFHMaximaPale     = laNewFHMaximaPale;

        //-------------------------------------------
        //-- Monitorizar (% Preparación del Palé)
        //-------------------------------------------

        //:: PATXI KOOP
        if ((this.$store.state.strDistribuidor == 'D070001') || (this.$store.state.strDistribuidor == 'D570001')) {
          this.lnMonitorizacion = 1;
        }

        //:: DISCEAS
        if ((this.$store.state.strDistribuidor == 'D050001') || (this.$store.state.strDistribuidor == 'D550001')) {
          this.lnMonitorizacion = 1;
        }
        
      },

      //...............................................
      //-- Refrescar Lista de Artículos NO CARGADOS
      //...............................................
      lp20RecargarArticulosNoCargados() {
        //console.log('lp20RecargarArticulosNoCargados');
      },

      //............................................................................................
      //-- Conversión (Date) en (String) de la Fecha Inicial y Final para el Detalle de Movimientos
      //............................................................................................
      lp30RangoFechasDetalleMovimientos() {
          //-- Extraer Fecha INICIAL
          let startDate    = this.dp1_dateRange.startDate;
          let fechaInicial = this.f92Date2DTZ(startDate).substr(0,10).replace(/-/g,'');

          let endDate      = this.dp1_dateRange.endDate;
          let fechaFinal   = this.f92Date2DTZ(endDate).substr(0,10).replace(/-/g,'');

          //-- Retornar el valor calculado
          //console.log(' fechaInicial = ('+fechaInicial+') // fechaFinal = ('+fechaFinal+')');

          this.ltFiltroDesdeFecha   = fechaInicial;
          this.ltFiltroHastaFecha   = fechaFinal;
      },
    
      //......................................
      //-- Indice del Palé Mínimo
      //......................................
      lf30PaleMinimo(indice) {
        return (this.lnTramoPales * this.lnPalesXTramo) + (this.lnPalesXPantalla * indice) + 1;
      },

      //......................................
      //-- Indice del Palé Máximo
      //......................................
      lf30PaleMaximo(indice) {
        return (this.lnTramoPales * this.lnPalesXTramo) + (this.lnPalesXPantalla * (indice+1));
      },



      //----------------------------------------------------------------
      //-- Seleccionar TODOS los Artículos PENDIENTES
      //----------------------------------------------------------------
      lp55SeleccionarTodosPendientes() {

        //-- Conmutar Valor
        this.lbCKArticulosNoCargados = !this.lbCKArticulosNoCargados;

        //-- Determinar si se deben MARCAR ó DESMARCAR todos los documentos
        if (this.lbCKArticulosNoCargados) {

          for (const item of this.laRegistrosPale[0]) {
              if(!this.laCKArticulosNoCargados.includes(item.ycl0)) {
                  this.laCKArticulosNoCargados.push(item.ycl0)
              }
          }

        } else {
          this.laCKArticulosNoCargados = [];
        }

      },     

      //----------------------------------------------------------------
      //-- Mover Artículos de PENDIENTES a PALE (x)
      //----------------------------------------------------------------
      lp59MoverPendientesAPale(indicePale) {
        //console.log('-- lp59MoverPendientesAPale --');
        //console.log(' -- laCKArticulosNoCargados --');
        //console.dir(this.laCKArticulosNoCargados);
        //console.log(' -- indicePale --');
        //console.dir(indicePale);

        if (this.lbCargaBloqueada) {
          this.p99AnuncioIA1('3','7','Aviso', 'Carga BLOQUEADA<br/><br/>Debes ABRIR la CARGA para realizar cambios.','',null);
          return;
        }

        //-- Definición de Variables
        let indice          = 0;
        let item            = null;

        //-- Recorrer la Lista
        while (indice < this.laRegistrosPale[0].length) {

            //-- Puntero al Artículo en la posición (indice)
            item = this.laRegistrosPale[0][indice];

            //-- Solo si ha sido marcada la (CheckBox) asociada
            if (this.laCKArticulosNoCargados.includes(item.ycl0)) {
                //-- Eliminar en ORIGEN
                indice = this.laRegistrosPale[0].indexOf(item);
                if (indice !== -1) {
                  this.laRegistrosPale[0].splice(indice, 1);
                }

                //-- Añadir a DESTINO
                this.laRegistrosPale[indicePale].push(item);
            } else {
              indice += 1;
            }

        }

        //-- Inicializar CheckBoxes
        this.laCKArticulosNoCargados = [];

      },

      //-----------------------------------------------------------------
      //-- Al cambiar de Almacenero Global
      //-----------------------------------------------------------------
      lp60CambioAlmaceneroGlobal() {
        let i = 0;

        while (i < this.laAlmacenerosPale.length) {
          //-- Reemplazar Almacenero particular del Pale(i)
          this.laAlmacenerosPale[i] = this.ltAlmaceneroGlobal;
          //-- Siguiente
          i += 1;
        }

      },

      //-----------------------------------------------------------------
      //-- Al cambiar de FECHA/RUTA/CARGA
      //-----------------------------------------------------------------
      lp60CambioFechaRutaCarga() {
        let item = this.laFechasRutasCargasDisponibles.filter((obj) => ((obj.ccl0 == this.ltFechaRutaCargaSeleccionada)));

        if ((item != null) && (item[0])) {

          //-- Actuaizar Rótulos
          this.ltPKCarga      = item[0].ccl0;
          this.ltFechaCarga   = this.f70AMD2fDMA(item[0].c001);
          this.ltRutaCarga    = '<b>'+item[0].c002 + ' - ' + item[0].r002 + '</b> <code>('+item[0].c009+')</code>';
          this.ltCamionCarga  = '<b>'+item[0].r007 + ' - ' + item[0].a003 + '</b> <code>('+item[0].a009+')</code>';
          this.ltTipoCYR      = '<code>('+item[0].a011+')</code>';
          this.lnPalesCarga   = item[0].a009;

          //-- Ampliar Número de Palés x Pantalla (para facilitar la edición)
          if ((item[0].a009 == 6) && (this.lnPalesXPantalla != 6)) { this.lnPalesXPantalla = 6; }
          if ((item[0].a009 == 8) && (this.lnPalesXPantalla != 8)) { this.lnPalesXPantalla = 8; }

        } else {

          //-- Inicializar Rótulos
          this.ltPKCarga      = '';
          this.ltFechaCarga   = '';
          this.ltRutaCarga    = '';
          this.ltCamionCarga  = '';
          this.ltTipoCYR      = '';
          this.lnPalesCarga   = 1;
        }

        //-- Actualizar Número de (Tabs)
        this.lp60RenovarListaPales();

        //-- Lectura Artículos x Palé
        this.lp98LeerDetalleCarga();

        //-- Eliminar Refresco Automático
        this.lp90EliminarTimer();

        //-- Inicializar Periodicidad (Segundos)
        this.lnSegundosTimer = 0;

        if (((this.$store.state.strDistribuidor == 'D070001') || (this.$store.state.strDistribuidor == 'D570001')) && (this.ltAmbitoBusqueda == '1')) { 
          this.lnSegundosTimer = 30; 
          this.lp90CambioTimer();
        }

      },

      //-----------------------------------------------------------------
      //-- Conmutar Bloqueo/Desbloqueo
      //-----------------------------------------------------------------
      lp60ConmutarBloqueo() {
        this.lbCargaBloqueada = !this.lbCargaBloqueada;
      },

      //-----------------------------------------------------------------
      //-- Establecer (Tab) Visible (con la Lista de Palés equivalente)
      //-----------------------------------------------------------------
      lp60VistaPales(indice) {
        this.lnVistaPalesIndiceActivo = indice;
      },

      //...............................................
      //-- Acumulado CAJAS en el Palé (x)
      //...............................................
      lf71AcumuladoCajasPale(indice) {
        if ((this.laRegistrosPale[indice]) && (this.laRegistrosPale[indice].length)) {
          return this.laRegistrosPale[indice].reduce(function(sum, r) {
                        if (r.a017 == 'C') { 
                            if (r.a040 == 'S') { return sum + r.y061 } else { return sum + r.y060; }
                        } else { return sum; }
                  }, 0);
        } else {
          return 0;
        }
      },

      //...............................................
      //-- Acumulado COEFICIENTES en el Palé (x)
      //...............................................
      lf71AcumuladoCoeficientesPale(indice) {
        if ((this.laRegistrosPale[indice]) && (this.laRegistrosPale[indice].length)) {
          return this.laRegistrosPale[indice].reduce(function(sum, r) {
                            return sum + r.y921;
                  }, 0);
        } else {
          return 0;
        }
      },

      //...............................................
      //-- Acumulado KILOS en el Palé (x)
      //...............................................
      lf71AcumuladoPesoPale(indice) {
        if ((this.laRegistrosPale[indice]) && (this.laRegistrosPale[indice].length)) {
          return this.laRegistrosPale[indice].reduce(function(sum, r) {
                            return sum + r.y912;
                  }, 0);
        } else {
          return 0;
        }
      },


      //...............................................
      //-- Acumulado UNIDADES en el Palé (x)
      //...............................................
      lf71AcumuladoUnidadesPale(indice) {
        if ((this.laRegistrosPale[indice]) && (this.laRegistrosPale[indice].length)) {
          return this.laRegistrosPale[indice].reduce(function(sum, r) {
                        if (r.a017 == 'B') { 
                            if (r.a040 == 'S') { return sum + r.y061 } else { return sum + r.y060; }
                        } else { return sum; }
                  }, 0);
        } else {
          return 0;
        }
      },

      //...............................................
      //-- Acumulado Nro. Referencias en el Palé (x)
      //...............................................
      lf71AcumuladoReferenciasPale(indice) {
        if ((this.laRegistrosPale[indice]) && (this.laRegistrosPale[indice].length)) {
          return this.laRegistrosPale[indice].length;
        } else {
          return 0;
        }
      },

      //...............................................
      //-- Acumulado COEFICIENTES en el Palé (x)
      //...............................................
      lf71PorcentajeOcupacionPale(indice) {
        let acumuladoPale = 0;

        //-- Acumular cada uno de los parciales
        if ((this.laRegistrosPale[indice]) && (this.laRegistrosPale[indice].length)) {
          acumuladoPale = this.laRegistrosPale[indice].reduce(function(sum, r) {
                            return sum + r.y991;
                  }, 0);
        }

        //-- Artículos Pendientes
        if (indice == 0) {
          acumuladoPale = acumuladoPale / this.lnPalesCarga;
        }

        //-- Retornar Resultados
        return acumuladoPale * 100;
      },


      //...............................................
      //-- Acumulado PREPARACIÓN del Palé (x)
      //...............................................
      lf71PorcentajePreparacionPale(indice) {

        //- Inicializaciones
        let acumuladoParcialesPreparados = 0;

        //-- Acumular cada uno de los parciales
        if ((this.laRegistrosPale[indice]) && (this.laRegistrosPale[indice].length)) {
          acumuladoParcialesPreparados = this.laRegistrosPale[indice].reduce(function(sum, r) {
                    if (r.y082 >= 4) { return sum + 1; } else { return sum ; }
                  }, 0);
        }

        //-- Indicador Global
        if (indice == 0) {
          acumuladoParcialesPreparados = acumuladoParcialesPreparados / this.lnPalesCarga;
        }

        //-- Retornar Resultados
        return acumuladoParcialesPreparados / this.laRegistrosPale[indice].length * 100;
      },


      //----------------------------------------------------------------------
      //-- Cambiar la Frecuencia de Refresco
      //----------------------------------------------------------------------    
      lp90CambioTimer() {

        //-- Eliminar Refresco Automático
        this.lp90EliminarTimer();

        //-- Programar Nuevo Refresco Automático
        if (this.lnSegundosTimer > 0) {
          this.p99Log(5,1,'.. Creando nuevo Timer cada '+this.lnSegundosTimer+' segundos ..');
          this.lrRefreshTimer = setInterval(function(){
              this.p99Log(5,1,'-- Inicio Lectura Fechas y Rutas con Carga Viva --');
              this.lp98LeerFechasRutasCargasDisponibles();
              this.p99Log(5,1,'-- Fin Lectura Fechas y Rutas con Carga Viva --');
            }.bind(this), this.lnSegundosTimer * 1000);
        }

      },   

      //----------------------------------------------------------------------
      //-- Eliminar el Refresco Automático
      //----------------------------------------------------------------------    
      lp90EliminarTimer() {
        this.p99Log(5,1,'** Eliminando ANTIGUO Timer **');

        //-- Eliminar Timer
        clearInterval(this.lrRefreshTimer);

      },


      //-------------------------------------------------------------------------------------------------
      //-- Cantidad a Mostrar como (Cantidad a Cargar)
      //-------------------------------------------------------------------------------------------------
      lf91CantidadACargar(a040,y060,y061) {
        if (a040 == 'S') {
          return '['+y061+']';      //-- Peso Variable --> [Unidades]
        } else {
          return y060;              //-- Peso Fjo --> Cantidad
        }
      },

      //-------------------------------------------------------------------------------------------------
      //-- Determinar la (Clase) a aplicar en cada Botón de la (Nav) asociada a los (Tramos de Palés)
      //-------------------------------------------------------------------------------------------------
      lf91ClaseNavPales(indice) {
        let clase = "flex-sm-fill text-sm-center nav-link";
        if (this.lnPalesXPantalla < 4) {
          clase += " igs-l2";
        } else {
          clase += " igs-vl4";
        }

        if (indice == this.lnVistaPalesIndiceActivo) { clase += " active"; }

        //-- Devolver la Clase COMPLETA calculada
        return clase;
      },

      //....................................................
      //-- Clase Barra Resumen con Estadísticas Palé
      //....................................................
      lf91ClaseResumenPale() {
        if (this.lnPalesXPantalla >= 8) {
          return "igs-color1 igs-l2";
        } else if (this.lnPalesXPantalla <= 4) {
          return "igs-color1 igs-l4";
        } else {
          return "igs-color1 igs-l3";
        }
      },

      //....................................................
      //-- Clase Barra Inferior (Almacenero)
      //....................................................
      lf91ClasePiePale() {
        return "igs-color1 igs-l2";
      },

      //..............................................................................
      //-- Clase para la Descripción de Artículo (según el estado de Monitorización)
      //..............................................................................
      lf91ClaseMonitorizacionFila(indice,estadoVerificacion) {
        //-- Clase a aplicar
        let clase = '';

        //-- Al monitorizar por (Estado de la Carga)
        if (this.lnMonitorizacion == 1) {
          if (estadoVerificacion >= 4) {
            clase = 'igs-color9';
          } else {
            clase = 'igs-color8';
          }
        }

        //-- Retornar Resultado
        return clase;
      },

      //....................................................
      //-- Reemplazar el NÚMERO DE PALÉS x PANTALLA (TAB)
      //....................................................
      lp60RenovarListaPales() {
        this.lnPalesNroTabs = this.lnPalesXTramo / this.lnPalesXPantalla;
        this.lnVistaPalesIndiceActivo = 0;
        //console.log(' lp60RenovarListaPales : '+(this.lnPalesNroMaximo / this.lnPalesXPantalla));
      },


      //....................................................
      //-- ¿ Permitido Mover Filas entre Palés ?
      //....................................................
      // eslint-disable-next-line no-unused-vars
      lf88PermitidoMover(evt, originalEvent) { 
        return !this.lbCargaBloqueada;
      },

      //....................................................
      //-- Almacenero del Palé (a)
      //....................................................
      lf91AlmaceneroPale(indice) {

        let texto = '';

        if (this.laAlmacenerosPale[indice] != '') {
          texto = this.laAlmacenerosPale[indice];
        }

        //-- Retornar la Descripción del Almacenero
        return texto;
      },

      //....................................................
      //-- Clase para el BANNER de cada PALÉ
      //....................................................
      lf91ClaseBannerPale(indice) {
        let clase = 'igs-color0 ';

        //.................................................
        //... Monitorizando (% Ocupación Palé)
        //.................................................
        if (this.lnMonitorizacion == 0) {
          let porcentaje = this.lf71PorcentajeOcupacionPale(indice);

          let color = 'igs-back-color2';

          if        (porcentaje >= 100) {
            color = 'igs-back-color4';
          } else if (porcentaje >= 66.66) {
            color = 'igs-back-green';
          } else if (porcentaje >= 33.33) {
            color = 'igs-back-color2';
          } else {
            color = 'igs-back-red';
          }

          //-- Agregar Color
          clase = clase + ' ' + color;
        }

        //.................................................
        //... Monitorizando (% Preparación Palé)
        //.................................................
        if (this.lnMonitorizacion == 1) {
          let porcentaje = this.lf71PorcentajePreparacionPale(indice);

          let color = 'igs-back-color2';

          if        (porcentaje >= 100) {
            color = 'igs-back-green';
          } else if (porcentaje >= 50.00) {
            color = 'igs-back-brown';
          } else if (porcentaje >= 0.01) {
            color = 'igs-back-orange';
          } else {
            color = 'igs-back-red';
          }

          //-- Agregar Color
          clase = clase + ' ' + color;
        }

        //...................................
        //-- Retornar la Clase COMPLETA
        //...................................
        return clase;
      },


      //....................................................
      //-- Clase para el DIV de cada PALÉ
      //....................................................
      lf91ClaseColumnaPale() { 
        //-- Valor Predeterminado
        let clase = 'col-md-4 igs-l3';                                  //-- Para 6 Palés (3 Arriba / 3 Abajo)

        if (this.lnPalesXPantalla == 2) { clase = 'col-md-12 igs-l5'; } //-- Para 2 Palés (1 Arriba / 1 Abajo)
        if (this.lnPalesXPantalla == 4) { clase = 'col-md-6 igs-l5'; }  //-- Para 4 Palés (2 Arriba / 2 Abajo)
        if (this.lnPalesXPantalla == 8) { clase = 'col-md-3 igs-l5'; }  //-- Para 8 Palés (4 Arriba / 4 Abajo)

        //-- Añadir Auto-Scrolling
        clase += ' igs-scroll-y';

        //-- Retornar la Clase Calculada
        return clase;
      },

      //....................................................
      //-- Clase para la DESCRIPCIÓN del ARTÍCULO
      //....................................................
      lf91ClaseDescripcionArticulo(descripcion) {
        if (descripcion.length > 27) {
          return 'igs-l2';
        } else {
          return 'igs-l3';
        }
      },

      //....................................................
      //-- Clase para cada FILA de un ARTÍCULO en el PALÉ
      //....................................................
      lf91ClaseFilaPale() {
        //-- Valor Predeterminado
        let clase = 'col-md-10 igs-l-pad-2 ';

        //-- Ajustar Tamaño del Texto
        if        (this.lnPalesXPantalla < 4) { 
          clase += 'igs-l5'; 
        } else if (this.lnPalesXPantalla > 6) { 
          clase += 'igs-l2'; 
        } else {
          clase += 'igs-l3';
        }

        //-- Retornar la Clase a aplicar
        return clase;
      },

      //....................................................
      //-- Clase para el PORCENTAJE DE OCUPACIÓN del PALÉ
      //....................................................
      lf91ClasePorcentajePale(indice) {
        let clase = "igs-center igs-bold";
        let porcentaje = this.lf71PorcentajeOcupacionPale(indice);

        let color = 'igs-color2';
        if        (porcentaje >= 100) {
          color = 'igs-color4';
        } else if (porcentaje >= 66.66) {
          color = 'igs-color9';
        } else if (porcentaje >= 33.33) {
          color = 'igs-color7';
        } else {
          color = 'igs-color8';
        }

        //-- Agregar Color
        clase = clase + ' ' + color;

        //-- Retornar la Clase COMPLETA
        return clase;
      
      },

      //....................................................
      //-- Maquetación Fecha y Hora de Carga
      //....................................................
      lf91FechaHoraCarga(fecha,hora) {
        //-- Inicializar
        let texto = '';

        //-- Mostrar valor (si hay algo que informar)
        if ((fecha != '00000000') && (hora != '000000') && (fecha.length == 8) && (hora.length == 6)) {
          texto = '     ['+
                  fecha.substr(6,2)+'-'+fecha.substr(4,2)+'-'+fecha.substr(2,2)+
                  ' '+
                  hora.substr(0,2)+':'+hora.substr(2,2)+':'+hora.substr(4,2)+']';
        }

        //-- Devolver el resultado
        return texto;
      },

      //................................................................
      //-- Rótulo de cada (Pill) equivalente a un (Tramo de Palés)
      //................................................................
      lf91RotuloTabPales(indice) {
        //-- Tramo
        let texto = this.lf30PaleMinimo(indice)+' - '+this.lf30PaleMaximo(indice);

        //-- Literal Previo
        if (this.lnPalesXPantalla > 2) { texto = 'Palés '+ texto; }

        //-- Retornar Rótulo Completo
        return texto;
      },


      //................................................................
      //-- Tiempo de Preparación del Palé
      //................................................................
      lf94TiempoPreparacionPale(indicePale) {

        //-- Determinar si se puede (o no) calcular la Diferencia de Tiempo
        if ((this.laFHMinimaPale[indicePale].length < 14) || (this.laFHMaximaPale[indicePale].length < 14) || (this.laFHMinimaPale[indicePale] == '99999999999999') || (this.laFHMaximaPale[indicePale] == '00000000000000')) {
          return '';
        } else {
          //-- Extraer las 2 Fechas/Horas a comparar
          let fh1 = this.laFHMinimaPale[indicePale];
          //console.log(fh1.substr(0,4)+'-'+fh1.substr(4,2)+'-'+fh1.substr(6,2)+'T'+fh1.substr(8,2)+':'+fh1.substr(10,2)+':'+fh1.substr(12,2)+'.000Z');
          let FHMinima = new Date(fh1.substr(0,4)+'-'+fh1.substr(4,2)+'-'+fh1.substr(6,2)+'T'+fh1.substr(8,2)+':'+fh1.substr(10,2)+':'+fh1.substr(12,2)+'.000Z');

          let fh2 = this.laFHMaximaPale[indicePale];
          let FHMaxima = new Date(fh2.substr(0,4)+'-'+fh2.substr(4,2)+'-'+fh2.substr(6,2)+'T'+fh2.substr(8,2)+':'+fh2.substr(10,2)+':'+fh2.substr(12,2)+'.000Z');

          //console.log('Pale ('+indicePale+') : '+fh1+' .. '+fh2);

          //-- Calcular la Diferencia
          var diffMs = (FHMaxima - FHMinima);                                   // Milisegundos entre AMBAS Fechas/Horas
          var diffDays = Math.floor(diffMs / 86400000);                         // Días
          var diffHrs = Math.floor((diffMs % 86400000) / 3600000);              // Horas
          var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);   // Minutos

          //-- Componer el Mensaje
          let texto = '<b>'+diffMins+'</b> Minutos';
          if (diffHrs > 0)  { texto = '<b>' + diffHrs + '</b> Horas y '   + texto; }
          if (diffDays > 0) { texto = '<b>' + diffDays  + '</b> Días, '   + texto; }
          return '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(' + texto + ')';
        }
      },

      //................................................................
      //-- Descripción del Almacenero
      //................................................................
      lf95DescripcionAlmacenero(item) {
        return item.o001 + ' - ' + item.o002;
      },

      //................................................................
      //-- Caracter para representar el ESTADO de la CARGA
      //................................................................
      lf95EstadoCarga(estado) {

        let caracter = '&nbsp;&#x2753;&nbsp;&nbsp;';                         //-- En curso (En Carga)

        if (estado == 1)  { caracter = '&#x2705;&nbsp;' }  //--  OK   (Completada)
        if (estado == 5)  { caracter = '&#x279C;&nbsp;' }  //-- (->)  (En Curso)
        if (estado == 6)  { caracter = '&#x2795;&nbsp;' }  //-- (?)   (Disponible - Sin Empezar)
        if (estado == 9)  { caracter = '&#x274C;&nbsp;' }  //-- (X)   (Bloqueada  - Sin Empezar)

        /*
        if (estado == 2) { caracter = '&#x2731;' }
        if (estado == 3) { caracter = '&#x279C;' }
        if (estado == 4) { caracter = '&#x271A;' }
        if (estado == 5) { caracter = '&#x2756;' }
        */

                
        //-- Retornar el Estado
        return caracter;
      },


      //----------------------------------------------------------------------
      //-- Actualizar Detalle de una Carga (Fecha+Ruta+Nro.Carga) en LOT
      //----------------------------------------------------------------------
      lp98GrabarDetalleCarga() {

        this.p99Log(5,1,'lp98GrabarDetalleCarga');

        this.p99Log(5,1,'-- qLOT --');
        let qLOT                = '';
        let iArticulo           = 0;
        let iPale               = 0;
        let r                   = null;
        let codigoAlmacenero    = '';

        //-- AGREGAR un registro (^) por cada PARCIAL (ARTICULO), separando cada ATRIBUTO con la virgulilla (~)
        while (iPale < this.laRegistrosPale.length) {
          //-- Recorrer la Lista de Parciales del presente Palé
          iArticulo = 0;
          while (iArticulo < this.laRegistrosPale[iPale].length) {

            //-- Acceder al Registro
            r = this.laRegistrosPale[iPale][iArticulo];

            //-- Determinar el Almacenero asociado
            codigoAlmacenero = this.laAlmacenerosPale[iPale];
            //if (codigoAlmacenero == '') { codigoAlmacenero = r.y092; }

            //-- Concatenar Atributos
            qLOT = qLOT + r.ycl0 + '~' + r.y006 + '~'  + r.y060 + '~' + r.y061 + '~' + 'ff0000' + '~' + 'A' + '~' + iPale + '~' + codigoAlmacenero + '^';

            //-- Siguiente Parcial
            iArticulo += 1;
          }


          //-- Siguiente Palé
          iPale += 1;
        }

        //-- Debug
        this.p99Log(1,1,'-- qLOT --');
        this.p99Log(1,1,qLOT,qLOT.length);

        //-- Llamada al Servidor
        var URL = global.lnkServerAppURL + "&s="+this.$store.state.strIDSesion+"&u="+this.$store.state.strUsuario+"&d="+this.$store.state.strDistribuidor+"&a="+this.$store.state.strAlmacen+"&t=200&m=951&x=" + encodeURIComponent(qLOT);
        //console.log('URL : ('+URL+')');

        //-- Contactar con el Servidor del Distribuidor
        axios.get(URL, {responseEncoding: 'utf-8'})                         
        .then(respuesta => {

            //-- Extraer la respuesta recibida
            var datos = respuesta.data;

            //-- Determinar si ha habido EXITO ó ERROR
            var flg = global.lnkGetDataError;
            if (datos.flg) {  flg = datos.flg; }

            //-- Actualizar Resultados
            this.p99Log(5,1,'-- Respuesta (200951) --');
            this.p99Log(5,2,datos);

            //-- Evaluar respuesta recibida
            if (flg == global.lnkGetDataOk)  {
              this.p99Log(1,1,' **** EXITO ****');
              this.p99AnuncioIA1('1','7','¡ Éxito !', 'La CARGA ha sido actualizada correctamente.',3);

              //-- Re-Lectura del Mapa a mostrar
              this.lp98LeerDetalleCarga();
            } else {
              this.p99Log(1,1,' #### ERROR (200951) ####');
              if (datos.msg) { this.p99AnuncioIA1('2','7','ERROR', datos.msg,-1); } else { this.p99AnuncioIA1('2','7','ERROR', respuesta.data,-1); }
            }
        })
        .catch(error => {
            //-- Mostrar el Mensaje de Error oportuno
            this.p99Log(1,1,'** ERROR **');
            this.p99Log(1,1,error);
        });

      },


      //----------------------------------------------------------------------
      //-- Lectura de la Lista de Almacaneros
      //----------------------------------------------------------------------
      lp98LeerAlmaceneros() {
        this.p99Log(5,1,' (lp98LeerAlmaceneros)');

        //-- Llamada al Servidor
        var URL = global.lnkServerAppURL + "&s="+this.$store.state.strIDSesion+"&u="+this.$store.state.strUsuario+"&d="+this.$store.state.strDistribuidor+"&a="+this.$store.state.strAlmacen+"&t=200&m=691";
        this.p99Log(1,1,' (lp98LeerAlmaceneros) URL = '+URL);

        //-- Contactar con el Servidor del Distribuidor
        axios.get(URL, {responseEncoding: 'utf-8'})                         
        .then(respuesta => {

            //-- Extraer la respuesta recibida
            var datos = respuesta.data;

            //-- Determinar si ha habido EXITO ó ERROR
            var flg = global.lnkGetDataError;
            if (datos.flg) {  flg = datos.flg; }

            //-- Actualizar Resultados
            this.p99Log(5,1,'-- Respuesta (200691) --');
            this.p99Log(5,2,datos);

            if (flg == global.lnkGetDataOk)  {

              //-- Reemplazar Lista de ALMACENEROS
              this.laListaAlmaceneros = datos.t200m691;

              //-- Mensaje de Éxito
              this.p99Log(5,1,' **** EXITO ****');

            } else {
              //-- Informar el Error
              this.p99Log(1,1,' #### ERROR (200691) ####');
              if (datos.msg) { this.p99AnuncioIA1('2','7','ERROR', datos.msg,-1); } else { this.p99AnuncioIA1('2','7','ERROR', respuesta.data,-1); }
            }
        })
        .catch(error => {
            //-- Mostrar el Mensaje de Error oportuno
            this.p99Log(1,1,'** ERROR **');
            this.p99Log(1,1,error);
        });

      },

      //----------------------------------------------------------------------
      //-- Lectura de la Relación de FECHAS+RUTAS con CARGA VIVA
      //----------------------------------------------------------------------
      lp98LeerFechasRutasCargasDisponibles() {
        this.p99Log(5,1,' (lp98LeerFechasRutasCargasDisponibles)');

        //-- Llamada al Servidor
        let parametros = this.ltAmbitoBusqueda;
        if (this.ltAmbitoBusqueda != '1') { parametros = parametros + '^' + this.ltFiltroDesdeFecha + '^' + this.ltFiltroHastaFecha; }
        var URL = global.lnkServerAppURL + "&s="+this.$store.state.strIDSesion+"&u="+this.$store.state.strUsuario+"&d="+this.$store.state.strDistribuidor+"&a="+this.$store.state.strAlmacen+"&t=200&m=601&x="+encodeURI(parametros);

        this.p99Log(1,1,' (lp98LeerFechasRutasCargasDisponibles) URL = '+URL);

        //-- Desactivar el AutoRefresco en modalidades que NO tienen "vida"
        if ((this.ltAmbitoBusqueda == '2') || (this.ltAmbitoBusqueda == '3')) {
          this.lnSegundosTimer = 0; 
          this.lp90CambioTimer();
        }

        //-- Contactar con el Servidor del Distribuidor
        axios.get(URL, {responseEncoding: 'utf-8'})                         
        .then(respuesta => {

            //-- Extraer la respuesta recibida
            var datos = respuesta.data;

            //-- Determinar si ha habido EXITO ó ERROR
            var flg = global.lnkGetDataError;
            if (datos.flg) {  flg = datos.flg; }

            //-- Actualizar Resultados
            this.p99Log(5,1,'-- Respuesta (200601) --');
            this.p99Log(5,2,datos);

            if (flg == global.lnkGetDataOk)  {

              //-- Reemplazar Lista de Fechas y Rutas con Carga Viva
              this.laFechasRutasCargasDisponibles = datos.t200m601;

              //-- Mensaje de Éxito
              this.p99Log(5,1,' **** EXITO ****');

            } else {
              //-- Informar el Error
              this.p99Log(1,1,' #### ERROR (200601) ####');
              if (datos.msg) { this.p99AnuncioIA1('2','7','ERROR', datos.msg,-1); } else { this.p99AnuncioIA1('2','7','ERROR', respuesta.data,-1); }
            }
        })
        .catch(error => {
            //-- Mostrar el Mensaje de Error oportuno
            this.p99Log(1,1,'** ERROR **');
            this.p99Log(1,1,error);
        });

      },

      //----------------------------------------------------------------------
      //-- Lectura de los ARTÍCULOS a CARGAR (detallados por Palé)
      //----------------------------------------------------------------------
      lp98LeerDetalleCarga() {
        this.p99Log(5,1,' (lp98LeerDetalleCarga)');

        //-----------------------
        //-- Inicializaciones
        //-----------------------
        this.lp10InicializarPales();

        //---------------------------
        //-- Llamada al Servidor
        //---------------------------
        var URL = global.lnkServerAppURL + "&s="+this.$store.state.strIDSesion+"&u="+this.$store.state.strUsuario+"&d="+this.$store.state.strDistribuidor+"&a="+this.$store.state.strAlmacen+"&t=200&m=651&x="+encodeURI(this.ltPKCarga+'^'+this.ltAmbitoBusqueda);
        this.p99Log(1,1,' (lp98LeerDetalleCarga) URL = '+URL);

        //-- Contactar con el Servidor del Distribuidor
        axios.get(URL, {responseEncoding: 'utf-8'})                         
        .then(respuesta => {

            //-- Extraer la respuesta recibida
            var datos = respuesta.data;

            //-- Determinar si ha habido EXITO ó ERROR
            var flg = global.lnkGetDataError;
            if (datos.flg) {  flg = datos.flg; }

            //-- Actualizar Resultados
            this.p99Log(5,1,'-- Respuesta (200651) --');
            this.p99Log(5,2,datos);

            if (flg == global.lnkGetDataOk)  {

              //-- Reemplazar Lista de ARTÍCULOS x PALÉ
              let i           = 0;
              let indicePale  = 0;
              let item        = null;

              while (i < datos.t200m651.length) {
                item = datos.t200m651[i];                                                         //-- Nuevo Parcial
                indicePale = item.y089;                                                           //-- Determinar el (Nro. Palé) asociado
                if ((indicePale < 0) || (indicePale > this.lnPalesNroMaximo)) { indicePale = 0; } // Revisar Nro. Palé

                //-- Nuevo Artículo en el Palé
                this.laRegistrosPale[indicePale].push(item);                                      //-- Agregar a la Lista

                //-- Actualizar Almacenero para el Palé
                if (item.y092 != '') {
                  if ((this.laAlmacenerosPale[indicePale] == undefined) || (this.laAlmacenerosPale[indicePale] == '') || (this.laAlmacenerosPale[indicePale] == item.y092)) {
                    this.laAlmacenerosPale[indicePale] = item.y092;
                  } else {
                    this.laAlmacenerosPale[indicePale] = '999';
                  }
                }

                //-- Actualizar Fecha/Hora MÍNIMA para el Palé
                if (this.laFHMinimaPale[indicePale] == undefined) { this.laFHMinimaPale[indicePale] = '99999999999999'; }
                if ((item.y093+item.y094 < this.laFHMinimaPale[indicePale]) && (item.y093 != '00000000')) { this.laFHMinimaPale[indicePale] = item.y093+item.y094; }

                //-- Actualizar Fecha/Hora MÁXIMA para el Palé
                if (this.laFHMaximaPale[indicePale] == undefined) { this.laFHMaximaPale[indicePale] = '00000000000000'; }
                if (item.y093+item.y094 > this.laFHMaximaPale[indicePale]) { this.laFHMaximaPale[indicePale] = item.y093+item.y094; }

                //-- Siguiente Parcial
                i += 1;                                                                           
              }

              //-- Mensaje de Éxito
              this.p99Log(5,1,' **** EXITO ****');

            } else {
              //-- Informar el Error
              this.p99Log(1,1,' #### ERROR (200651) ####');
              if (datos.msg) { this.p99AnuncioIA1('2','7','ERROR', datos.msg,-1); } else { this.p99AnuncioIA1('2','7','ERROR', respuesta.data,-1); }
            }
        })
        .catch(error => {
            //-- Mostrar el Mensaje de Error oportuno
            this.p99Log(1,1,'** ERROR **');
            this.p99Log(1,1,error);
        });

      }

  
    },
  
    /**************************************************************/
    /****************       Filtros / Formatos     ****************/
    /**************************************************************/
    filters: {
        //..............................................
        //--- Filtro para dar FORMATO a las FECHAS
        //..............................................
        lf60FiltroFecha: function (date) {

            let dw = date.toLocaleDateString('es-ES', { weekday: 'long' });
            dw = dw.charAt(0).toUpperCase() + dw.slice(1);

            //-- Fecha
            const d = date.getDate();
            const m = date.getMonth() + 1;
            //const y = date.getFullYear();
            const y = date.getFullYear();

            //-- Hora
            //const hora = date.getHours();
            //const minutos = date.getMinutes();

            //return dw + ' ' + (d < 10 ? '0' : '') + d + '-' + (m < 10 ? '0' : '') + m + '-' + y + ' ' + (hora < 10 ? '0' : '') + hora + ':' + (minutos < 10 ? '0' : '') + minutos;
            return dw + ' ' + (d < 10 ? '0' : '') + d + '-' + (m < 10 ? '0' : '') + m + '-' + y;
        }
    },

    //----------------------------
    //-- Datos Calculados
    //----------------------------
    computed: {
  
    },
  
    //----------------------------
    //-- Al cargar esta página
    //----------------------------
    mounted() {
        //-- Actualizar Módulo "Vivo"
        this.$store.commit('p10StrModuleName', 'SVC31200');

        //-- Inicializar variables relacionadas con Palés
        this.lp10InicializarPales();

        //-- Lectura de Fechas/Rutas con Cargas Disponibles
        this.lp98LeerAlmaceneros();
        this.lp98LeerFechasRutasCargasDisponibles();
  
        //-- Actualizar BreadCrumb 
        this.$store.commit('p10StrMenuBC', [
          {
            text: "3. Salidas",
            href: "#"
          },
          {
            text: "3.1 Pre-Cargas",
            href: "#",
            to: "/31000"
          },
          {
            text: "3.1.2 Cargas x Palé",
            active: true
          }
        ]);

       //-- Replicar Desde/Hasta Fecha sobre los campos (texto) equivalentes
       this.lp30RangoFechasDetalleMovimientos();

    },

    //---------------------------------------
    //-- Antes de abandonar la Página Actual
    //---------------------------------------
    beforeDestroy () {
      this.p99Log(5,1,'** Eliminando Timer (beforeUnmount) **');
      this.lp90EliminarTimer();
    }

  
  }
  </script>
  
<style lang="scss">
.nav-pills > a.active {
    background-color: $bc1 !important;
  }  
</style>
  